@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.title-text{
    font-family: "Bebas Neue", sans-serif;
}


.img{
    height:100px;
    width:100px;
}